<template>
  <div
    :class="classes"
    aria-atomic
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    classes() {
      return ['z-form-invalid-feedback', this.state === false && 'z-form-invalid-feedback-visible']
    },
  },
}
</script>
