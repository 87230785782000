<template>
  <div :class="classes">
    <label
      v-if="(label || $slots.label) && !isFieldset"
      :for="labelFor"
      class="d-block"
    >
      <template v-if="label">{{ label }}</template>
      <template v-else><slot name="label" /></template>
    </label>
    <fieldset
      v-else-if="(label || $slots.label) && isFieldset"
      class="z-form-group mb-0"
    >
      <legend
        tabindex="-1"
        :class="['z-no-focus-ring', { 'col-form-label': label || $slots.label }, 'pt-0']"
      >
        <div class="field-label">
          <template v-if="label">
            {{ label }}
          </template>
          <template v-else>
            <slot name="label" />
          </template>
        </div>
      </legend>
    </fieldset>
    <div class="position-relative">
      <slot />
      <ZFormInvalidFeedback
        :state="state"
        :tab-index="invalidFeedback ? '-1' : null"
        :aria-live="feedbackAriaLive"
      >
        <slot
          v-if="$slots['invalid-feedback']"
          name="invalid-feedback"
        />
        <template v-else>
          {{ invalidFeedback }}
        </template>
      </ZFormInvalidFeedback>
      <small
        v-if="description"
        tabindex="-1"
        class="form-text text-muted"
      >{{ description }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },

    labelFor: {
      type: String,
      default: null,
    },

    state: {
      type: Boolean,
      default: null,
    },

    invalidFeedback: {
      type: String,
      default: null,
    },

    description: {
      type: String,
      default: null,
    },

    feedbackAriaLive: {
      type: String,
      default: 'assertive',
    },
  },

  computed: {
    classes() {
      return ['z-form-group', this.state === false && 'is-invalid']
    },

    isFieldset() {
      return !this.labelFor
    },
  },
}
</script>
